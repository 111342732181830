import React from "react";
import { useState } from "react";
import createOrganization from "./api/CreateOrganization";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import "./App.css";

function App() {
  const [organizationName, setOrganizationName] = useState("");
  const [email, setEmail] = useState("");
  const [responseSuccess, setResponseSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState({ nameError: "", emailError: "" });

  const validation = (e) => {
    const message = organizationName
      ? e?.target?.value?.trimStart()
      : e?.target?.value?.replaceAll(" ", "");
    const specialCase = /.*[-/`~!#$@_%+=.,^&(){}[\];:"<>?\\]/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

    if (e?.target?.id === "email")
      if (!message.match(emailRegex))
        setErrorMessage({
          ...errorMessage,
          emailError: "Please match the format requested ex: example@domain.com"
        });
      else setErrorMessage({ ...errorMessage, emailError: "" });
    else if (e?.target?.id === "name")
      if (message.match(specialCase))
        setErrorMessage({
          ...errorMessage,
          nameError: "Please create a name with only alphanumeric characters."
        });
      else if (message.length < 4)
        setErrorMessage({
          ...errorMessage,
          nameError: "Name length should be more than 4."
        });
      else if (message.length === 225)
        setErrorMessage({
          ...errorMessage,
          nameError: "The length of the name cannot be more than 225 characters"
        });
      else setErrorMessage({ ...errorMessage, nameError: "" });
  };

  const changeOrgName = (e) => {
    validation(e);
    setOrganizationName(e.target.value);
  };

  const changeEmail = (e) => {
    validation(e);
    setEmail(e.target.value);
  };

  const createOrg = async () => {
    injectStyle();
    const payload = {
      name: organizationName,
      email: email
    };
    try {
      const result = await createOrganization(payload);
      if (result.status === 201) setResponseSuccess(true);
    } catch (error) {
      console.log("Error: ", error);
      setResponseSuccess(false);
    }
  };
  return (
    <div className="App">
      <ToastContainer />

      <nav className="navbar fixed-top navbar-expand-md ">
        <div className="container-fluid container">
          <a className="navbar-brand" href="# ">
            {" "}
            <img src="../images/Logo.png" height="30" alt="..." />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul
                className="navbar-nav justify-content-end flex-grow-1 pe-3"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <li className="nav-item">
                  <a className="nav-link" aria-current="page" href="#subscribe">
                    Subscribe
                  </a>
                </li>
                <li
                  className="nav-item"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <a className="nav-link" href="#learn_more">
                    Learn more
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <div className="hero">
        <div className="hero__overlay"></div>
        <div className="hero__mask"></div>
        <div className="hero__inner">
          <div className="container">
            <div className="hero__content">
              <div className="hero__content__inner" id="navConverter">
                <h1 className="hero__title">Project Management</h1>
                <p className="hero__text">A place to handle your projects</p>
                <a href="#subscribe" className="button button__accent">
                  Subscribe
                </a>
                <a href="#learn_more" className="button hero__button">
                  Learn more
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="steps landing__section" id="learn_more">
        <div className="container">
          <h2 className="step-header">Who can use Project Management?</h2>
        </div>
        <div className="container">
          <div className="steps__inner">
            <div className="step">
              <div className="step__media">
                <img
                  alt="..."
                  src="./images/undraw_designer.svg"
                  className="step__image"
                />
              </div>
            </div>
            <div className="step">
              <div className="step__media">
                <img
                  alt="..."
                  src="../images/undraw_responsive.svg"
                  className="step__image"
                />
              </div>
            </div>
            <div className="step">
              <div className="step__media">
                <img
                  alt="..."
                  src="./images/undraw_creation.svg"
                  className="step__image"
                />
              </div>
            </div>
          </div>
          <div className="step__text">
            It is intended for use by management specialists, from different
            directions such as IT , time management, by project managers, team
            leaders, developers, QA etc.
          </div>
        </div>
      </div>

      <div className="expanded landing__section">
        <div className="container">
          <div className="expanded__inner">
            <div className="expanded__media">
              <img
                alt="..."
                src="./images/undraw_browser.svg"
                className="expanded__image"
              />
            </div>
            <div className="expanded__content">
              <p className="expanded__text">
                The purpose of developing this application is to organise the
                management process more efficiently and easily. To simplify and
                order more processes to help the user work more productively as
                a team. To achieve a more productive result by using the
                possibilities the application offers.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <img
          alt="..."
          src="../images/pircing_plan.png"
          className="pircing_plan"
        />
      </div>

      <div className="payment_box">
        <div className="payment_methods">
          <div>
            <img alt="..." src="../images/Credit_card.png" />
          </div>
          <img alt="..." src="../images/paypal.png" />
          <div>
            <img alt="..." src="../images/google_pay.png" />
          </div>
          <div>
            <img alt="..." src="../images/apple_pay.png" />
          </div>
        </div>
        <div>
          <img alt="..." src="../images/credit_box.png" />
        </div>
      </div>

      <div
        className="cta cta--reverse"
        id="subscribe"
        style={{ padding: "6pt 0" }}
      >
        <div
          className="container"
          style={{ justifyContent: "center", display: "flex" }}
        >
          {!responseSuccess ? (
            <div className="cta__inner">
              <fieldset className="form-group">
                <input
                  className={`${errorMessage.nameError && "error-input"} input`}
                  placeholder="Organization name"
                  maxLength={225}
                  onChange={changeOrgName}
                  id="name"
                />
                {errorMessage.nameError && <p className="error">{errorMessage.nameError}</p>}
              </fieldset>
              <fieldset className="form-group">
                <input
                  className={`${errorMessage.emailError && "error-input"} input`}
                  placeholder="Email"
                  id="email"
                  onChange={changeEmail}
                />
                {errorMessage.emailError && <p className="error">{errorMessage.emailError}</p>}
              </fieldset>
              <button
                className="button button__accent"
                onClick={createOrg}
                disabled={email === "" || organizationName === "" || errorMessage.nameError !== "" || errorMessage.emailError !== ""}
                data-testid="button"
              >
                Subscribe
              </button>
            </div>
          ) : (
            <div className="footer_success">
              You will receive a link in your email to continue registration
            </div>
          )}
        </div>
      </div>

      <div className="footer footer--dark">
        <div className="container">
          <div className="footer__inner">
            <div className="footer__textLogo">Project Management</div>
            <div className="footer__textLogo">
              <div>Adress:</div>
              <div
                className="link"
                style={{ color: "white", cursor: "pointer" }}
              >
                northwebsolution@gmail.com
              </div>
            </div>
            <div className="footer__textLogo">
              <div style={{ cursor: "pointer" }}>GitHub</div>
              <div style={{ cursor: "pointer" }}>Twitter</div>
            </div>
            <div className="footer__textLogo">© 2024 All rights reserved</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
